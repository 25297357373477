/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Page } from "../Layouts/Page";
import { SiteLogo } from "../Features/Images";
import { MyGrid } from "../Styles/MyGrid";
import "../App.css";
import {
  FieldContainer,
  FieldInput,
  FieldLabel,
  Fieldset,
  PrimaryButton,
} from "../Styles/Emotion";

export const ShopperSignUp = () => {
  const [divided, setDivided] = React.useState<boolean>(false);
  React.useEffect(() => {
    setDivided(false);
  }, [divided]);
  return (
    <Page title={"Shopper Sign Up"}>
      <form
        css={css`
          text-align: left;

          @media screen and (min-width: 1024px) {
            margin: 0 200px;
          }

          @media screen and (max-width: 415px) {
            margin: 0 10px;
            padding-bottom: 20px;
          }
        `}
      >
        <Fieldset>
          <div
            css={css`
              text-align: center !important;
              margin-bottom: 15px;
            `}
          >
            <SiteLogo light={true} logoWidth={"200px"} />
          </div>
          <MyGrid columnsCount={2} divided={divided}>
            <FieldContainer className="FieldContainerMobile">
              <FieldLabel htmlfor="firstname">First name:</FieldLabel>
              <FieldInput id="firstname" type="text" />
              <FieldLabel htmlfor="lastname">Last name:</FieldLabel>
              <FieldInput id="lastname" type="text" />
              <FieldLabel htmlfor="displayname">Display name:</FieldLabel>
              <FieldInput id="displayname" type="text" />
              <FieldLabel htmlfor="dob">Date of birth:</FieldLabel>
              <FieldInput id="dob" type="text" />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel htmlfor="email">Phone number:</FieldLabel>
              <FieldInput id="email" type="text" />
              <FieldLabel htmlfor="email">Email address:</FieldLabel>
              <FieldInput id="email" type="text" />
              <FieldLabel htmlfor="confirmemail">
                Confirm email address:
              </FieldLabel>
              <FieldInput id="confirmemail" type="text" />
              <FieldLabel htmlfor="password">Password:</FieldLabel>
              <FieldInput id="password" type="password" />
            </FieldContainer>
          </MyGrid>
          <div
            css={css`
              text-align: center;
              width: 100%;
              margin-top: 0px;
              width: 100%;
            `}
          >
            <PrimaryButton>Submit</PrimaryButton>
          </div>
        </Fieldset>
      </form>
    </Page>
  );
};
