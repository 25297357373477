/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Page } from "../Layouts/Page";
import VimeoEmbed from "../Features/Video";
import { MyGrid } from "../Styles/MyGrid";
import { ShopperImage, SupplierImage } from "../Features/Images";
import { getDeviceType } from "../Helper Functions/Helpers";

export const LandingPage = () => {
  const [divided, setDivided] = React.useState<boolean>(false);
  React.useEffect(() => {
    setDivided(!getDeviceType());
  }, [divided]);
  return (
    <Page>
      <div
        css={css`
          font-weight: lighter;
          padding: 0 20px;
        `}
      >
        <h1
          css={css`
            font-weight: lighter;
            padding: 30px 0px;
          `}
        >
          Welcome to the first universal store!
        </h1>
        <div
          css={css`
            margin-top: 0px;
            @media screen and (min-width: 1024px) {
              margin-right: 250px;
              margin-left: 250px;
            }
          `}
        >
          <VimeoEmbed embedId="1zzjewNF0yg" />
          <MyGrid columnsCount={2} divided={divided}>
            <ShopperImage
              paragraph={"I am a shopper. I want to place an order."}
            />
            <SupplierImage
              paragraph={"I am a supplier. I want to fulfill customer orders."}
            />
          </MyGrid>
        </div>
      </div>
    </Page>
  );
};
