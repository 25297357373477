import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const gray1 = "#383737";
export const gray2 = "#5c5a5a";
export const gray3 = "#857c81";
export const gray4 = "#b9b9b9";
export const gray5 = "#e3e2e2";
export const gray6 = "#f7f8fa";
export const primary1 = "#d99552";
export const primary2 = "black";
export const seconday1 = "black";
export const seconday2 = "#d99552";
export const accent1 = "#dbb365";
export const accent2 = "#efd197";
export const fontFamily = "'Segoe UI', 'Helvetica Neue',sans-serif";
export const fontSize = "16px";
export let buttonWidth = "100%";

export const PrimaryButton: any = styled.button`
  background-color: ${primary2};
  border-radius: 5px;
  font-family: ${fontFamily};
  font-size: ${fontSize};
  border: none;
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  width: ${buttonWidth};
  :hover {
    background-color: ${primary1};
  }
  :focus {
    outline-color: ${primary2};
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const SecondayButton: any = styled.button`
  background-color: ${seconday2};
  border-style: solid;
  border-radius: 5px;
  font-family: ${fontFamily};
  font-size: ${fontSize};
  padding: 5px 10px;
  color: white;
  cursor: pointer;
  width: 90%;
  :hover {
    background-color: ${seconday1};
  }
  :focus {
    outline-color: ${seconday2};
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const Fieldset: any = styled.fieldset`
  margin: 10px auto 0 auto;
  padding: 30px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${gray5};
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
`;

export const FieldContainer: any = styled.div`
  margin-bottom: 10px;
`;

export const FieldLabel: any = styled.label`
  font-size: 16px;
  color: grey;
`;

const baseFieldCSS = css`
  box-sizing: border-box;
  font-family: ${fontFamily};
  font-size: ${fontSize};
  margin-bottom: 5px;
  padding: 8px 10px;
  border: 1px solid ${gray5};
  border-radius: 3px;
  color: ${gray2};
  background-color: white;
  width: 100%;
  :focus {
    outline-color: ${gray5};
  }
  :disabled {
    background-color: ${gray6};
  }
`;

export const FieldInput: any = styled.input`
  ${baseFieldCSS}
  margin-top:5px;
  ::placeholder {
    color: grey;
  }
`;

export const FieldTextArea: any = styled.textarea`
  ${baseFieldCSS}
  height:110px;
`;

export const FormButtonContainer: any = styled.div`
  margin: 30px 0px 0px 0px;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid ${gray5};
`;

export const FieldDiv: any = styled.div`
  margin: -10px auto 0 auto;
  padding: 30px;
  width: 350px;
`;
