/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { SiteLogo } from "./Images";
import { PrimaryButton } from "../Styles/Emotion";
import { DefaultButton } from "../Styles/Buttons";
import "../App.css";
import { FieldContainer, FieldInput, FieldLabel } from "../Styles/Emotion";
import { CustomFieldSet, CustomFieldDiv } from "../Styles/CustomeTools";

export const SignInContainer = () => {
  let width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  let fieldsetWidth = "380px !important;";
  console.log(width);
  if (width <= 380) {
    fieldsetWidth = "310px !important;";
  }
  const navigate = useNavigate();
  const HandleSignInNavigate = () => {
    navigate("/products");
  };
  return (
    <div>
      <form
        css={css`
          text-align: left;
        `}
      >
        <CustomFieldSet width={fieldsetWidth}>
          <FieldContainer>
            <div
              css={css`
                text-align: center !important;
                margin-bottom: 5px;
              `}
            >
              <SiteLogo light={true} logoWidth={"200px"} />
            </div>
            <FieldLabel htmlfor="email">Email:</FieldLabel>
            <FieldInput type="text" />
            <FieldLabel htmlfor="password">Password:</FieldLabel>
            <FieldInput id="password" type="password" />
            <Link to="forgotpassword">
              <span
                css={css`
                  font-size: 12px;
                  font-weight: bold;
                `}
              >
                Forgotten your password?
              </span>
            </Link>
            <br />
            <br />
            <PrimaryButton
              type="submit"
              css={css`
                width: 100%;
              `}
              onClick={HandleSignInNavigate}
            >
              Sign In
            </PrimaryButton>
          </FieldContainer>
        </CustomFieldSet>
      </form>
      <CustomFieldDiv width={fieldsetWidth}>
        <p
          css={css`
            text-align: center;
            font-weight: bolder;
          `}
        >
          Or
        </p>
        <DefaultButton color={"#3b5998"} width={"100%"}>
          Continue with Facebook
        </DefaultButton>
        <br />
        <br />
        <DefaultButton color={"#df4938"} width={"100%"}>
          Continue with Google
        </DefaultButton>
        <p
          css={css`
            font-size: 10px;
            margin-top: 10px;
          `}
        >
          By continuing, you agree to find-it-all's Terms of Service, Privacy
          Policy.
        </p>
      </CustomFieldDiv>
    </div>
  );
};
