/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PageCustomer } from "../Layouts/PageCustomer";
import sampleImage from "../Images/sample.png";

export const CustomerProducts = () => {
  return (
    <PageCustomer title="">
      <img alt="sample" src={sampleImage} width="150px" />
      <p>Product</p>
      <h4>R15</h4>
    </PageCustomer>
  );
};
