/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Menu } from "semantic-ui-react";
import { SiteLogo, HBMenuIcon } from "./Images";
import { Link } from "react-router-dom";
import { HBMenu } from "./HBMenu";
import React from "react";

export const DesktopMenu = () => {
  return (
    <Menu
      pointing
      secondary
      css={css`
        font-size: 18px !important;
        box-shadow: 0 3px 7px 0 rgba(110, 112, 114, 0.21) !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 0px 0px;
        .item {
          color: white !important;
          padding: 7px !important;
        }

        @media screen and (max-width: 1440px) and (min-width: 745px) {
          padding-right: 60px !important;
          padding-left: 60px !important;
        }

        @media screen and (max-width: 737px) and (min-width: 319px) {
          padding-right: 20px !important;
          padding-left: 20px !important;
        }
      `}
    >
      <Menu.Item
        css={css`
          font-weight: bold !important;
        `}
      >
        <Link
          to="/"
          css={css`
            color: white;
          `}
        >
          <SiteLogo light={false} logoWidth={"150px"} />
        </Link>
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item>
          <Link
            to="signup"
            css={css`
              color: white;
            `}
          >
            Sign Up
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="signup"
            css={css`
              color: white;
            `}
          >
            |
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link
            to="login"
            css={css`
              color: white;
              ::hover {
                color: #b23850 !important;
              }
            `}
          >
            Log In
          </Link>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export const MobileMenu = () => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleMouseDown = () => {
    toggleMenu();
  };

  const toggleMenu = () => {
    setVisible(!visible);
  };

  return (
    <Menu
      pointing
      secondary
      css={css`
        font-size: 18px !important;
        box-shadow: 0 3px 7px 0 rgba(110, 112, 114, 0.21) !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        padding: 0px 0px;
        .item {
          color: white !important;
          padding: 7px !important;
        }

        @media screen and (max-width: 1440px) and (min-width: 745px) {
          padding-right: 60px !important;
          padding-left: 60px !important;
        }

        @media screen and (max-width: 737px) and (min-width: 319px) {
          padding-right: 20px !important;
          padding-left: 20px !important;
        }
      `}
    >
      <Menu.Item
        css={css`
          font-weight: bold !important;
        `}
      >
        <Link
          to="/"
          css={css`
            color: white;
          `}
        >
          <SiteLogo light={false} logoWidth={"150px"} />
        </Link>
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item>
          <HBMenuIcon width={"40px"} handleMouseDown={handleMouseDown} />
          <HBMenu menuVisibility={visible} handleMouseDown={handleMouseDown} />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};
