/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import sitelogo from "../Images/logo-black.png";
import sitelogoAlt from "../Images/logo-white.png";
import supplier from "../Images/supplier.png";
import shopper from "../Images/shopper.png";
import hbmenu from "../Images/menu.png";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../Styles/Emotion";

interface logoProps {
  light: boolean;
  logoWidth: string;
}

export const SiteLogo = ({ light, logoWidth }: logoProps) => {
  let logo = "";
  if (!light) {
    logo = sitelogo;
  } else {
    logo = sitelogoAlt;
  }
  return (
    <img
      css={css`
        width: ${logoWidth} !important;
        margin-top: 5px;
      `}
      src={logo}
      alt="logo"
    />
  );
};

interface Props {
  paragraph: string;
}

export const ShopperImage = ({ paragraph }: Props) => {
  const navigate = useNavigate();
  const HandleShopperNavigate = () => {
    navigate("/shoppersignup");
  };
  return (
    <div>
      <p>{paragraph}</p>
      <img
        css={css`
          width: 150px;
          margin-bottom: 25px;
        `}
        src={shopper}
        alt="logo"
      />
      <br />
      <PrimaryButton
        css={css`
          width: 70%;
        `}
        onClick={HandleShopperNavigate}
      >
        Click here
      </PrimaryButton>
    </div>
  );
};

export const SupplierImage = ({ paragraph }: Props) => {
  const navigate = useNavigate();
  const HandleSupplierNavigate = () => {
    navigate("/suppliersignup");
  };
  return (
    <div>
      <p>{paragraph}</p>
      <img
        css={css`
          width: 150px;
          margin-bottom: 30px;
        `}
        src={supplier}
        alt="logo"
      />
      <br />
      <PrimaryButton
        css={css`
          width: 70%;
        `}
        onClick={HandleSupplierNavigate}
      >
        Click here
      </PrimaryButton>
    </div>
  );
};

interface menuProps {
  width: string;
  handleMouseDown: () => void;
}

export const HBMenuIcon = ({ width, handleMouseDown }: menuProps) => {
  return (
    <img
      id="roundButton"
      css={css`
        width: ${width} !important;
      `}
      src={hbmenu}
      alt="logo"
      onMouseDown={handleMouseDown}
    />
  );
};
