import { Grid, GridColumn, SemanticWIDTHS } from "semantic-ui-react";
import React from "react";

interface Props {
  columnsCount: SemanticWIDTHS;
  divided: boolean;
  children: React.ReactNode[];
}

export const MyGrid = ({ columnsCount, divided, children }: Props) => {
  if (divided) {
    return (
      <Grid divided doubling columns={columnsCount}>
        {children.map((child, index) => {
          return <GridColumn key={index}>{child}</GridColumn>;
        })}
      </Grid>
    );
  } else {
    return (
      <Grid doubling columns={columnsCount}>
        {children.map((child, index) => {
          return <GridColumn key={index}>{child}</GridColumn>;
        })}
      </Grid>
    );
  }
};
