/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./Features/Header";
import { LandingPage } from "./Pages/LandingPage";
import { SignUp } from "./Pages/SignUp";
import { LogIn } from "./Pages/LogIn";
import { ShopperSignUp } from "./Pages/ShopperSignUp";
import { SupplierSignUp } from "./Pages/SupplierSignUp";
import { CustomerProducts } from "./Pages/CustomerProducts";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div className="App" css={css``}>
        <Header />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="shoppersignup" element={<ShopperSignUp />} />
          <Route path="suppliersignup" element={<SupplierSignUp />} />
          <Route path="login" element={<LogIn />} />
          <Route path="products" element={<CustomerProducts />} />
          <Route path="*" />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
