/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { CSSProperties } from "react";

interface Props {
  Id: string;
  style: CSSProperties;
  placehoder: string;
}

export const SearchBox = ({ Id, style, placehoder }: Props) => {
  return (
    <div
      css={css`
        padding: 15px;
        background-color: #39579e;
        border-radius: 4px;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
      `}
    >
      <input id={Id} type="text" style={style} placeholder={placehoder} />
    </div>
  );
};
