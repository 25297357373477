/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Page } from "../Layouts/Page";
import { MyGrid } from "../Styles/MyGrid";
import { getDeviceType } from "../Helper Functions/Helpers";

import { ShopperImage, SupplierImage } from "../Features/Images";

export const SignUp = () => {
  const [divided, setDivided] = React.useState<boolean>(false);
  React.useEffect(() => {
    setDivided(!getDeviceType());
  }, [divided]);
  return (
    <Page title={"Sign Up"}>
      <div
        css={css`
          margin-top: 40px;
          font-weight: lighter;
          z-index: -99;
        `}
      ></div>
      <MyGrid columnsCount={2} divided={divided}>
        <ShopperImage paragraph={"I am a shopper. I want to place an order."} />
        <SupplierImage
          paragraph={"I am a supplier. I want to fulfill orders."}
        />
      </MyGrid>
    </Page>
  );
};
