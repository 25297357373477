/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { PageTitle } from "./PageTitle";

interface Props {
  title?: string;
  children: React.ReactNode;
}

export const Page = ({ title, children }: Props) => {
  return (
    <div
      css={css`
        font-size: 20px;
        margin: 90px 200px 0px 200px;
        position: relative;
        padding-bottom: 100px;
        z-index: 0;

        @media screen and (min-width: 2560px) {
          margin: 90px 400px 0px 400px;
        }

        @media screen and (min-width: 10243x) {
          margin: 90px 0px 0px 0px;
        }

        @media screen and (min-width: 767px) {
          margin: 90px 20px 20px 20px;
        }

        @media screen and (max-width: 415px) {
          margin: 90px auto 20px auto;
        }
      `}
    >
      {title && (
        <PageTitle>
          <span
            css={css`
              font-size: 25px;
              color: #8590aa;
            `}
          >
            {title}
          </span>
        </PageTitle>
      )}
      {children}
    </div>
  );
};
