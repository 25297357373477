/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Page } from "../Layouts/Page";
import { SignInContainer } from "../Features/SignInContainer";

export const LogIn = () => {
  return (
    <Page title="Log In">
      <div css={css``}>
        <SignInContainer />
      </div>
    </Page>
  );
};
