/**@ImportSource @emotion/react */
import { css } from "@emotion/react";
import { Input, Label, Menu, MenuItemProps } from "semantic-ui-react";
import React from "react";

export const VertMenu = () => {
  const [activeItem, setActiveItem] = React.useState<string | null | undefined>(
    "input"
  );
  const handleItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { name }: MenuItemProps
  ) => {
    setActiveItem(name);
  };
  return (
    <Menu vertical>
      <Menu.Item
        name="shop"
        active={activeItem === "shop"}
        onClick={handleItemClick}
      >
        <Label color="teal">1</Label>
        Shopping cart
      </Menu.Item>

      <Menu.Item
        name="products"
        active={activeItem === "products"}
        onClick={handleItemClick}
      >
        <Label>3</Label>
        My products
      </Menu.Item>

      <Menu.Item
        name="orders"
        active={activeItem === "orders"}
        onClick={handleItemClick}
      >
        <Label>1</Label>
        Orders
      </Menu.Item>

      <Menu.Item
        name="offers"
        active={activeItem === "offers"}
        onClick={handleItemClick}
      >
        <Label color="green">1</Label>
        Offers
      </Menu.Item>

      <Menu.Item
        name="mydetails"
        active={activeItem === "mydetails"}
        onClick={handleItemClick}
      >
        Personal details
      </Menu.Item>
      <Menu.Item
        name="help"
        active={activeItem === "help"}
        onClick={handleItemClick}
      >
        Help
      </Menu.Item>
    </Menu>
  );
};
