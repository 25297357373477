/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Page } from "../Layouts/Page";
import { SiteLogo } from "../Features/Images";
import { MyGrid } from "../Styles/MyGrid";
import "../App.css";
import {
  FieldContainer,
  FieldInput,
  FieldLabel,
  Fieldset,
  FieldTextArea,
  PrimaryButton,
} from "../Styles/Emotion";

export const SupplierSignUp = () => {
  const [divided, setDivided] = React.useState<boolean>(false);
  React.useEffect(() => {
    setDivided(false);
  }, [divided]);
  return (
    <Page title={"Supplier Sign Up"}>
      <form
        css={css`
          text-align: left;

          @media screen and (min-width: 1024px) {
            margin: 0 200px;
          }

          @media screen and (max-width: 415px) {
            margin: 0 10px;
            padding-bottom: 20px;
          }
        `}
      >
        <Fieldset>
          <div
            css={css`
              text-align: center !important;
              margin-bottom: 15px;
            `}
          >
            <SiteLogo light={true} logoWidth={"200px"} />
          </div>
          <MyGrid columnsCount={2} divided={divided}>
            <FieldContainer className="FieldContainerMobile">
              <FieldLabel htmlfor="businessname">Business name:</FieldLabel>
              <FieldInput id="businessname" type="text" />
              <FieldLabel htmlfor="category">Category:</FieldLabel>
              <FieldInput id="category" type="text" />
              <FieldLabel htmlfor="description">Description:</FieldLabel>
              <FieldTextArea id="description" type="text" />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel htmlfor="email">Phone number:</FieldLabel>
              <FieldInput id="email" type="text" />
              <FieldLabel htmlfor="email">Email address:</FieldLabel>
              <FieldInput id="email" type="text" />
              <FieldLabel htmlfor="confirmemail">
                Confirm email address:
              </FieldLabel>
              <FieldInput id="confirmemail" type="text" />
              <FieldLabel htmlfor="password">Password:</FieldLabel>
              <FieldInput id="password" type="password" />
            </FieldContainer>
          </MyGrid>
          <hr
            css={css`
              border-top: 1px solid #f9f9f9;
            `}
          />
          <div
            css={css`
              margin: 10px 0;
            `}
          >
            <h4>Physcial Address</h4>
          </div>
          <MyGrid columnsCount={2} divided={divided}>
            <FieldContainer className="FieldContainerMobile">
              <FieldLabel htmlfor="addressline1">Address line 1:</FieldLabel>
              <FieldInput id="addressline1" type="text" />
              <FieldLabel htmlfor="addressline2">Address line 2:</FieldLabel>
              <FieldInput id="addressline2" type="text" />
              <FieldLabel htmlfor="surburbtownship">
                Surburb/Township:
              </FieldLabel>
              <FieldInput id="surburbtownship" type="text" />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel htmlfor="city">City:</FieldLabel>
              <FieldInput id="city" type="text" />
              <FieldLabel htmlfor="province">Province:</FieldLabel>
              <FieldInput id="province" type="text" />
              <FieldLabel htmlfor="postalcode">Postal Code:</FieldLabel>
              <FieldInput id="postalcode" type="text" />
            </FieldContainer>
          </MyGrid>
          <hr
            css={css`
              border-top: 1px solid #f9f9f9;
            `}
          />
          <div
            css={css`
              margin: 10px 0;
            `}
          >
            <h4>Contact Person Information</h4>
          </div>
          <MyGrid columnsCount={2} divided={divided}>
            <FieldContainer className="FieldContainerMobile">
              <FieldLabel htmlfor="firstname">First name:</FieldLabel>
              <FieldInput id="firstname" type="text" />
              <FieldLabel htmlfor="lastname">Last Name:</FieldLabel>
              <FieldInput id="lastname" type="text" />
            </FieldContainer>
            <FieldContainer>
              <FieldLabel htmlfor="email">Phone number:</FieldLabel>
              <FieldInput id="email" type="text" />
              <FieldLabel htmlfor="email">Email address:</FieldLabel>
              <FieldInput id="email" type="text" />
            </FieldContainer>
          </MyGrid>
          <hr
            css={css`
              border-top: 1px solid #f9f9f9;
            `}
          />
          <div
            css={css`
              margin: 10px 0;
            `}
          >
            <h4>Business Verfication</h4>
            <p
              css={css`
                font-size: 12px;
              `}
            >
              Upload a valid company letterhead with business address. Upload
              contact person ID.
            </p>
          </div>
          <div
            css={css`
              text-align: center;
              width: 100%;
              margin-top: 0px;
              width: 100%;
            `}
          >
            <PrimaryButton>Submit</PrimaryButton>
          </div>
        </Fieldset>
      </form>
    </Page>
  );
};
