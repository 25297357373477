import styled from "@emotion/styled";
import React from "react";

interface Props {
  width: string;
  children: React.ReactNode;
}

export const CustomFieldSet = ({ width, children }: Props) => {
  const Fieldset: any = styled.fieldset`
    margin: 10px auto 0 auto;
    padding: 30px;
    background-color: white;
    width:${width}
    border-radius: 4px;
    border: 1px solid #e3e2e2;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  `;
  return <Fieldset>{children}</Fieldset>;
};

export const CustomFieldDiv = ({ width, children }: Props) => {
  const FieldDiv: any = styled.div`
    margin: -10px auto 0 auto;
    padding: 30px;
    width: ${width};
  `;
  return <FieldDiv>{children}</FieldDiv>;
};
