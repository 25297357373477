/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { DesktopMenu, MobileMenu } from "./Menus";

export const Header = () => {
  let width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  let showMobileMenu = false;
  if (width <= 770) {
    showMobileMenu = true;
  }
  return (
    <div>
      <div
        css={css`
          background-color: black !important;
          width: 100%;
          position: fixed;
          z-index: 1;
          top: 0;
        `}
      >
        <div
          css={css`
            @media screen and (min-width: 100em) {
              padding-right: 6rem;
              padding-left: 6rem;
              max-width: calc(1300px + 24px);
              margin-left: auto;
              margin-right: auto;
            }
          `}
        >
          {!showMobileMenu && <DesktopMenu />}
          {showMobileMenu && <MobileMenu />}
        </div>
      </div>
    </div>
  );
};
