/**@jsxImportSource @emotion/react */
import "../Menu.css";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

interface Props {
  menuVisibility: boolean;
  handleMouseDown: () => void;
}

export const HBMenu = ({ menuVisibility, handleMouseDown }: Props) => {
  var visibility = "hide";

  if (menuVisibility) {
    visibility = "show";
  }
  return (
    <div id="flyoutMenu" className={visibility} onMouseDown={handleMouseDown}>
      <div>
        <Link to="/">Home</Link>
      </div>
      <hr />
      <div>
        <Link to="signup">Sign Up</Link>
      </div>
      <hr />
      <div>
        <Link to="login">Log In</Link>
      </div>
    </div>
  );
};
