/**@jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from 'semantic-ui-react';
import React from 'react';

interface Props {
  color: string;
  children: React.ReactNode;
  type?: string;
  width?: string;
  onclick?: Function;
}

export const DefaultButton = ({ color, children, type, width }: Props) => {
  return (
    <Button
      type={type}
      secondary
      css={css`
        background-color: ${color} !important;
        width: ${width};
        color: white !important;
      `}
    >
      {children}
    </Button>
  );
};
