/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { PageTitle } from "./PageTitle";
import { VertMenu } from "../Features/VertMenu";
import { Fieldset } from "../Styles/Emotion";
import { SearchBox } from "../Features/Search";
import { MyGrid } from "../Styles/MyGrid";

interface Props {
  title?: string;
  children: React.ReactNode;
}

export const PageCustomer = ({ title, children }: Props) => {
  const searchStyle = {
    width: "100%",
    border: "none",
    borderRadius: "3px",
    fontSize: "1rem",
    FontWeight: "500",
    height: "30px",
    lineHeight: "normal",
    margin: "0",
    padding: "8px 8px 8px 13px",
    Position: "relative",
    ZIndex: "100",
  };
  return (
    <div
      css={css`
        font-size: 20px;
        margin: 90px 200px 0px 200px;
        position: relative;
        padding-bottom: 100px;
        z-index: 0;

        @media screen and (min-width: 2560px) {
          margin: 90px 400px 0px 400px;
        }

        @media screen and (min-width: 10243x) {
          margin: 90px 0px 0px 0px;
        }

        @media screen and (min-width: 767px) {
          margin: 90px 20px 20px 20px;
        }

        @media screen and (max-width: 415px) {
          margin: 90px auto 20px auto;
        }
      `}
    >
      {title && (
        <PageTitle>
          <span
            css={css`
              font-size: 25px;
              color: #8590aa;
            `}
          >
            {title}
          </span>
        </PageTitle>
      )}
      <div
        css={css`
          text-align: left;

          @media screen and (min-width: 1024px) {
            margin: 0 200px;
          }

          @media screen and (max-width: 415px) {
            margin: 0 10px;
            padding-bottom: 20px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            display: -webkit-flex;
            width: 100%;
          `}
        >
          <div
            css={css`
              flex: 1;
              -webkit-flex: 1;
            `}
          >
            <VertMenu />
          </div>
          <div
            css={css`
              flex: 1;
              -webkit-flex: 4;
            `}
          >
            <SearchBox
              Id={"products"}
              style={searchStyle}
              placehoder={"Search products..."}
            />
            <MyGrid columnsCount={4} divided={false}>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
              <Fieldset>{children}</Fieldset>
            </MyGrid>
          </div>
        </div>
      </div>
    </div>
  );
};
