/**@jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { Embed } from "semantic-ui-react";
import explainer from "../Images/explainer.png";

interface Props {
  embedId: string;
}

const VimeoEmbed = ({ embedId }: Props) => (
  <Embed
    placeholder={explainer}
    url="./src/Images/explainervid.mp4"
    css={css`
      margin-bottom: 30px;
    `}
  />
);

export default VimeoEmbed;
